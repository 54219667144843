<template>
    <div id="assetLocationHistory" class="baseTemplate">

        <!-- begin:: mobile menu -->
        <app-mobilemenu></app-mobilemenu>
        <!-- end:: mobile menu -->

        <div class="kt-grid kt-grid--hor kt-grid--root">

            <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--ver kt-page" style=" margin-top: -55px; ">

                <!-- begin:: home menu -->
                <app-siteconfigurationmenu activeItem="assetLocationHistory"></app-siteconfigurationmenu>
                <!-- end:: home menu -->

                <div class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor kt-wrapper">

                    <!-- begin:: header -->
                    <app-header></app-header>
                    <!-- end:: header -->

                    <app-accesserrorportlet ref="accessErrorPortlet" v-show="!accessGranted" activeItem="assetTrackingDashboard" :siteId="siteId"></app-accesserrorportlet>

                    <div v-if="accessGranted" class="kt-grid__item kt-grid__item--fluid kt-grid kt-grid--hor" style="background: white;">

                        <app-locationmap ref="locationmap" mode="assetlocationhistory"></app-locationmap>
                        <v-tour id="vueTour" name="playbackTour" :steps="stepsVueTour" :options="optionsVueTour">
                            <template slot-scope="tour">
                                <transition name="fade">
                                    <v-step
                                        v-if="tour.steps[tour.currentStep]"
                                        :key="tour.currentStep"
                                        :step="tour.steps[tour.currentStep]"
                                        :previous-step="tour.previousStep"
                                        :next-step="tour.nextStep"
                                        :stop="tour.stop"
                                        :skip="tour.skip"
                                        :is-first="tour.isFirst"
                                        :is-last="tour.isLast"
                                        :labels="tour.labels"
                                    >
                                        <template v-if="tour.currentStep === 0">
                                            <div slot="actions">
                                                <input v-model="chxVueTour" type="checkbox" class="kt-checkbox" style="position: relative; top: 3px;"/> {{ $t("playbace_vueTourLabelSkipNextTime") }}
                                                <button @click="tour.skip" class="v-step__button-skip">{{ $t("playback_vueTourLabelSkip") }}</button>
                                                <button @click="tour.nextStep" class="v-step__button v-step__button-next">{{ $t("playback_vueTourLabelNext") }}</button>
                                            </div>
                                        </template>
                                        <template v-else-if="tour.currentStep === 1">
                                            <div slot="actions">
                                                <button @click="tour.previousStep" class="v-step__button v-step__button-previous">{{ $t("playback_vueTourLabelPrevious") }}</button>
                                                <button @click="tour.nextStep" class="v-step__button v-step__button-next">{{ $t("playback_vueTourLabelNext") }}</button>
                                            </div>
                                        </template>
                                        <template v-else-if="tour.currentStep === 2">
                                            <div slot="actions">
                                                <button @click="tour.previousStep" class="v-step__button v-step__button-previous">{{ $t("playback_vueTourLabelPrevious") }}</button>
                                                <button @click="tour.skip" class="v-step__button-skip">{{ $t("playback_vueTourLabelSkip") }}</button>
                                            </div>
                                        </template>
                                    </v-step>
                                </transition>
                            </template>
                        </v-tour>

                        <div style="display: flex; margin-top: 10px;">

                            <label class="col-form-label text-dark font-weight-normal" style="margin-left: 10px;">{{ $t("playback_startDateLabelFrom") }}:</label>

                            <div class="ml-2">
                                <input type="text" id="playbackStartDateTimePicker" :placeholder="$t('playback_startDatePlaceholderFrom')" class="form-control selectDateInput" autocomplete="off"/>
                                <div class="kt-font-danger font-weight-normal" style="font-size: 11px;" v-show="!vStartDateTime">
                                    {{ $t("error_fieldIsRequired") }}
                                </div>
                            </div>

                            <label class="col-form-label text-dark font-weight-normal ml-2">{{ $t("playback_endDateLabelTo") }}:</label>

                            <div class="ml-2">
                                <input type="text" id="playbackEndDateTimePicker" :placeholder="$t('playback_endDatePlaceholderTo')" class="form-control selectDateInput" autocomplete="off"/>
                                <div class="kt-font-danger font-weight-normal" style="font-size: 11px;" v-show="!vEndDateTime">
                                    {{ $t("error_fieldIsRequired") }}
                                </div>
                                <div class="kt-font-danger font-weight-normal" style="font-size: 11px;" v-show="vEndDateTime && !isCorrectEndDateSelected">
                                    {{ $t("error_playbackEndDateNotCorrect") }}
                                </div>
                            </div>

                            <div class="actionControlContainer">
                                <a href="#" :class="isCorrectDateSelected ? 'btn btn-icon btn-success btn-circle mr-2' : 'btn btn-icon btn-success btn-circle mr-2 disabled'" @click.prevent="onClickPlay" v-show="!isPlaying">
                                    <i class="fas fa-play pl-1"></i>
                                </a>
                                <a href="" class="btn btn-icon btn-dark btn-circle mr-2" @click.prevent="onClickResume" v-show="isPlaying">
                                    <i class="fas fa-pause"></i>
                                </a>
                                <a href="" class="btn btn-icon btn-info btn-circle mr-2" @click.prevent="onClickReplay" v-show="isPlaying">
                                    <i class="fas fa-undo-alt"></i>
                                </a>
                            </div>

                            <label class="col-form-label text-dark font-weight-normal">{{ $t("playback_speedLabel") }}:</label>

                            <div class="sliderContainer ml-3">
                                <div id="slider" style="width:150px;"></div>
                                <span id="sliderValue" class="sliderValue text-dark kt-font-bold"></span>
                            </div>

                            <span class="assetCount">{{ $tc("playback_assetCount", currentPlaybackAsset) }}</span>

                        </div>

                        <div id="timeline" class="timelineContainer" v-show="accessGranted"></div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import LocationMap from "../map/locationmap.vue";
import AccessErrorPortlet from "../utils/accesserrorportlet.vue";
import L from "leaflet";
import commonVueHelper from "../../helpers/commonVueHelper";
import i18n from "../../i18n";
import moment from "moment";
const Playback = require("../../../public/assets/leafletplayback/LeafletPlayback");
const vis = require("../../../public/assets/vis/vis"); // https://visjs.github.io/vis-timeline/docs/timeline/

export default {
    data() {
        return {
            siteId: this.$route.params.siteId,
            siteBuildingsTable: null,
            playback: null, // Playback plugin management
            timeline: null, // Vis plugin management
            vSpeed: 1, // Control of play speed value
            vStartDateTime: null, // History start date
            vEndDateTime: null, // History end date
            isPlaying: false, // Boolean which permits to know if playback is playing
            isResume: false, // Boolean which permits to know if playback is resume
            isHistoryDataReady: false, // When period is select this value change when data is ready
            currentFloorHistoryData: null, // Contains the id of floor which data are loaded
            isCorrectDateSelected: false, // Permits to know if date and time are correct
            isCorrectEndDateSelected: false, // Permits to know if end date > start date,
            currentHistoryDataFloorId: null,
            isMultipledDaysSelected: false, // Is true when user selects period of more than 1 day
            previousHistoryData: null, // Contains the history data for previous period when user selected more than 1 day
            historyDataToDisplayed: null, // Contains the filtered data to displayed
            historyDataPeriodRange: [], // Contains the period range like {start: timestamp, end: timestamp, isExecuted: tre}
            currentPeriodStep: 0, // Will be increment for each period request
            timeBeforeSendRequest: null, // Contains the value of time where cursor is before sending request,
            currentPlaybackAsset: 0, // The count of current assets which found on playback card
            isMounted: false,
            stepsVueTour: [
                {
                    target: "#sidebaritem_filters_link",
                    content: i18n.t("playback_vueTourStep1Content"),
                    header: {
                        title: i18n.t("playback_vueTourStep1Title"),
                    },
                    params: {
                        placement: "right",
                        highlight: false
                    }
                },
                {
                    target: "#floorSelectorButton",
                    content: i18n.t("playback_vueTourStep2Content"),
                    header: {
                        title: i18n.t("playback_vueTourStep2Title")
                    },
                    params: {
                        highlight: false
                    }
                },
                {
                    target: ".actionControlContainer",
                    content: i18n.t("playback_vueTourStep3Content"),
                    header: {
                        title: i18n.t("playback_vueTourStep3Title"),
                    },
                    params: {
                        highlight: false
                    }
                }
            ],
            optionsVueTour: {
                startTimeout: 1000,
                placement: 'bottom'
            },
            chxVueTour: localStorage && localStorage.getItem("vueTourSkipNextTime") !== null ? JSON.parse(localStorage.getItem("vueTourSkipNextTime")) : false
        };
    },
    created: function() {
        console.log("Component(assetLocationHistory)::created() - called");
        // get wall types
        this.getWallTypes();
        // Update the string of datetimepicker today
        $.fn.datetimepicker.dates['fr'].today = 'Maintenant';
        $.fn.datetimepicker.dates['en'].today = 'Now';
    },
    mounted: function() {
        console.log("Component(assetLocationHistory)::mounted() - Init metronic layout");
        this.isMounted = true;
        KTLayout.init();
        // Init speed slider
        this.initSlider();
        // Init select date and time
        this.initStartDateTimePicker();
        this.initEndDateTimePicker();
    },
    destroyed: function() {
        console.log("Component(assetLocationHistory)::destroyed() - called");
        if (this.playback) {
            window.clearInterval(this.playback._intervalID);
        }
        // Destroy date time picker
        commonVueHelper.destroyDateTimePicker($("#playbackStartDateTimePicker"));
        commonVueHelper.destroyDateTimePicker($("#playbackEndDateTimePicker"));
    },
    beforeRouteLeave(to, from, next) {
        this.resetWallsState();
        this.resetGatewaysState();
        this.resetAutocalibrationTagsState();
        this.resetGeofencesState();
        this.resetWalkwaysState();
        this.resetAssetsState();
        next();
    },
    watch: {
        // Watchs wallTypes
        wallTypes() {
            if (this.wallTypes) {
                // Get site wall
                this.getSiteWalls(this.siteId);
            }
        },

        // Watchs site walls
        siteWalls() {
            if (this.siteWalls) {
                // get BLE gateways
                this.getSiteBLEGateways(this.siteId);
            }
        },

        // Watchs siteBLEGateways
        siteBLEGateways() {
            if (this.siteBLEGateways) {
                // get autocalibrationTags, if any
                this.getSiteAutoCalibrationTags(this.siteId);
            }
        },

        // Watchs siteAutoCalibrationTags
        siteAutoCalibrationTags() {
            if (this.siteAutoCalibrationTags) {
                // Get geofences
                this.getSiteGeofences(this.siteId);
            }
        },

        // Watchs siteGeofences
        siteGeofences() {
            if (this.siteGeofences && this.isMounted) {
                // Now start playback tour if is mounted
                if (!this.chxVueTour) {
                    this.$tours['playbackTour'].start();
                }
            }
        },

        // Watch the changed of speed
        vSpeed() {
            console.log("Component(assetLocationHistory)::watch(vSpeed) called with : ", this.vSpeed);
            if (this.playback) {
                // Change the speed of playback by speed selection of user
                this.playback.setSpeed(this.vSpeed);
            }
        },

        // Watch the loading of asset history
        assetLocationHistory(assetLocationHistory) {
            console.log("Component(assetLocationHistory)::watch(assetLocationHistory) called with : ", assetLocationHistory);
            // Update the history data value, set that for this period request is already executed
            this.historyDataPeriodRange[this.currentPeriodStep].isExecuted = true;
            // When data is loaded we can prepare the environment to load the load playback data
            this.playAssetsTracking();
        },

        // Watch the changed of user language
        user() {
            console.log("Component(assetLocationHistory)::watch(user) called with : ", this.user);
            this.initVis(this.vStartDateTime, this.vEndDateTime);
            this.initStartDateTimePicker();
            this.initEndDateTimePicker();
        },

        // Watch vue tour change checkbox
        chxVueTour(value) {
            console.log("Component(assetLocationHistory)::watch(chxVueTour) called with : ", value);
            if (localStorage) {
                localStorage.setItem("vueTourSkipNextTime", value);
            }
        },

        // Watch the changed of displayed information
        assetsLabelsDisplayedValues() {
            console.log("Component(assetLocationHistory)::watch(assetsLabelsDisplayedValues) called with : ", this.assetsLabelsDisplayedValues);
            if (this.playback && this.playback._map && this.isAssetsLabelsDisplayed) {
                // If playback exists and user switch on the checkbox to display the asset tooltip => Calll function to display tooltip according to row
                this.displayAssetsTooltipsAccordingRowValues();
            }
        },

        // Watch the changed of playback
        playback() {
            console.log("Component(assetLocationHistory)::watch(playback) called with : ", this.playback);
            // If tooltip must be displayed and playback exist display tooltip according to row
            if (this.playback && this.isAssetsLabelsDisplayed) {
                this.displayAssetsTooltipsAccordingRowValues();
            }
        },

        // Called each time a change is made on filters
        activeFilters(value) {
            console.log("Component(assetLocationHistory)::watch(activeFilters) called with : ", value);
            this.updatePlaybackDataAccordingFilter();
        },

        // Called each time user updates status of the switch filtering button
        isMapFilteringEnabled(state) {
            console.log("Component(assetLocationHistory)::watch(isMapFilteringEnabled) called with : ", state);
            this.updatePlaybackDataAccordingFilter();
        },

        // Watch the changed of isAssetsLabelsDisplayed
        isAssetsLabelsDisplayed: function(value) {
            /*
                Two cases:
                1. If value is true, show tooltip according to row
                2. If value is false close tooltip for each asset
            */
            console.log("Component(assetLocationHistory)::watch(isAssetsLabelsDisplayed) called with : ", value);
            if (value) {
                if (this.playback && window.leafletMap) {
                    // Display the tooltip
                    this.displayAssetsTooltipsAccordingRowValues();
                }
            } else {
                // For each assets, close tooltip
                for (let markerIdx in window.leafletMap._layers) {
                    if (window.leafletMap._layers.hasOwnProperty(markerIdx)
                        && window.leafletMap._layers[markerIdx].feature
                        && window.leafletMap._layers[markerIdx].feature.properties
                        && window.leafletMap._layers[markerIdx].feature.properties.asset
                    ) {
                        let marker = window.leafletMap._layers[markerIdx];
                        marker.closeTooltip();
                    }
                }
            }
        },

        // Watch when user change selected floor
        getSelectedFloorId: function(newFloorId, oldFloorId) {
            // Verify if floor current floor is same or not of history data floor
            if (newFloorId && newFloorId !== this.currentHistoryDataFloorId && this.isHistoryDataReady) {
                // clear markers on map
                for (let markerIdx in window.leafletMap._layers) {
                    if (window.leafletMap._layers.hasOwnProperty(markerIdx)
                        && window.leafletMap._layers[markerIdx].feature
                        && window.leafletMap._layers[markerIdx].feature.properties
                        && window.leafletMap._layers[markerIdx].feature.properties.asset
                    ) {
                        let marker = window.leafletMap._layers[markerIdx];
                        if (marker.options.opacity !== 0) {
                            marker.closeTooltip();
                            marker.setOpacity(0);
                        }
                    }
                }
                // Reset all state to initial
                this.resetPlayback();
            }
        }

    },
    computed: {
        ...mapGetters([
            "isAccessGranted",
            "getSelectedFloorId",
            "getSelectedBuildingId",
            "activeFilters",
            "assetLocationHistory",
            "siteAssets",
            "user",
            "assetsLabelsDisplayedValues",
            "isAssetsLabelsDisplayed",
            "activeFilters",
            "isMapFilteringEnabled",
            "currentPlaybackTimeStamp",
            "wallTypes",
            "siteWalls",
            "siteBLEGateways",
            "siteAutoCalibrationTags",
            "siteGeofences"
        ]),

        accessGranted: function() {
            if (this.isMounted) {
                return this.$refs.accessErrorPortlet.isAccessGranted();
            } else return true; // Do not display error portlet by default
        }
    },
    methods: {
        ...mapActions([
            "getWallTypes",
            "getSiteBLEGateways",
            "getSiteAutoCalibrationTags",
            "getSiteGeofences",
            "getSiteWalls",
            "resetWallsState",
            "resetGatewaysState",
            "resetAutocalibrationTagsState",
            "resetGeofencesState",
            "resetWalkwaysState",
            "resetAssetsState",
            "resetMapMode",
            "resetSearchedItems",
            "getAssetLocationHistory",
            "getSiteAssets",
            "setCurrentOpenedAssetIdInDashboard",
            "setCurrentPlaybackTimestamp"
        ]),

        // Call when the data of asset histories have been received
        playAssetsTracking() {
            // Init vis only when not already init with user date time select
            if (!this.timeline) {
                this.initVis(this.vStartDateTime, this.vEndDateTime);
                // Open now the bar to show the timeline
                $("#map").css("height", "calc(100vh - 265px)");
            }
            // Init playback with data which has been load
            this.initPlayback();
        },

        // A callback so timeline is set after changing playback time using by vis component
        onPlaybackTimeChange (ms) {
            console.log(this.playback);
            this.displayAssetsTooltipsAccordingRowValues();
            // Update the blue cursor to new time
            this.timeline.setCustomTime(new Date(ms));
            // Update the current playaback time stamp
            this.setCurrentPlaybackTimestamp(ms);
            /**
             * Warning: Verify if is not the time put by the initPlayback function in this case set only timeline
             * In other case verify if ms is on period where request is not already executed
             */
            if (ms !== this.timeBeforeSendRequest) {
                /**
                 * Three cases here
                 * 1. We are in period where request not executed in this case send request for this period
                 * 2. We are in end of current period in this case send the request of next period
                 * 3. We are in the end of user choice period in this case stop or resume playback
                 */
                const currentPeriodEnd = this.historyDataPeriodRange[this.currentPeriodStep].end;
                const timeStampPeriod = this.getTimeStampPeriod(ms);
                if (timeStampPeriod && !timeStampPeriod.isExecuted) {
                    // The ms is on zone where request has not executed
                    const timeStampPeriodIndex = this.getTimeStampPeriodIndex(ms);
                    // Update period step
                    this.currentPeriodStep = timeStampPeriodIndex;
                    // Send request for next periods
                    const startTimeStamp = this.historyDataPeriodRange[this.currentPeriodStep].start;
                    const endTimeStamp = this.historyDataPeriodRange[this.currentPeriodStep].end;
                    this.timeBeforeSendRequest = ms;
                    // Send request for timestamp period
                    this.getAssetLocationInPeriod(startTimeStamp, endTimeStamp);
                } else if (currentPeriodEnd === ms) {
                    // We are on the end of current period, if is multiple date and currentPeriodStep is not the end of range send next request
                    if (this.isMultipledDaysSelected && this.currentPeriodStep < this.historyDataPeriodRange.length - 1) {
                        // Update current period step for next period
                        this.currentPeriodStep += 1
                        // Send request for next periods
                        const startTimeStamp = this.historyDataPeriodRange[this.currentPeriodStep].start;
                        const endTimeStamp = this.historyDataPeriodRange[this.currentPeriodStep].end;
                        this.timeBeforeSendRequest = ms;
                        // Send request for timestamp period
                        this.getAssetLocationInPeriod(startTimeStamp, endTimeStamp);
                    } else {
                        // We are on the end of period stop playback
                        this.onClickResume();
                    }
                } else if (ms === this.vEndDateTime.getTime()){
                    // We are on the end of period stop playback
                    this.onClickResume();
                }
            }
        },

        // A callback during user drop in timeline
        onCustomTimeChange(properties) {
            // Update the playback cursor only when the period of timestamp request is already executed
            const timeStampPeriod = this.getTimeStampPeriod(properties.time.getTime());
            if (timeStampPeriod && timeStampPeriod.isExecuted) {
                // Set the time of playback
                this.playback.setCursor(properties.time.getTime());
            }
        },

        // A callback when user finish to drop the timeline, set here the cursor of playback
        onCustomTimeChanged(properties) {
            this.playback.setCursor(properties.time.getTime());
        },

        // Binding action of play button click
        onClickPlay() {
            // Disable select date and time not permits the changing when search is already click
            this.disableDateSelect2();
            // Two cases here
            // 1. User click on resume or stop and not change the period, the playback can play if floor not change
            // 2. When periods are changed, or floor is changed new request must be sent
            if (this.isResume && this.playback && (this.getSelectedFloorId === this.currentHistoryDataFloorId)) {
                this.hideFloorMapSelector();
                this.isPlaying = true;
                this.isResume = false;
                // Display asset tooltip
                this.displayAssetsTooltipsAccordingRowValues();
                // Start Playback
                this.playback.start();
            } else {
                this.isResume = false;
                this.executeAssetLocationRequest();
            }
        },

        // Binding of resume button click
        onClickResume() {
            // If playback exists stop it and set the boolean to false to enable the play button and disable other buttons
            if (this.playback) {
                this.playback.stop();
                this.isPlaying = false;
                this.isResume = true;
                this.enableDateSelect2();
                this.showFloorMapSelector();
            }
        },

        // Binding of replay button click
        onClickReplay() {
            //Two cases here:
            //1- playback is playing => stop it and set the play cursor at the begining and start playback
            //2- playback is not playing (resume case) => set the cursor on the begining and start it
            this.setCurrentOpenedAssetIdInDashboard("");
            if (this.playback && this.playback.isPlaying()) {
                this.playback.stop();
                this.playback.setCursor(this.playback.getStartTime());
                this.timeline.setCustomTime(this.vStartDateTime.getTime());
                this.isPlaying = true;
                this.isResume = false;
                this.hideFloorMapSelector();
                this.disableDateSelect2();
                // Display asset tooltip
                this.displayAssetsTooltipsAccordingRowValues();
                // Start playback
                this.playback.start();
            } else if (this.playback && !this.playback.isPlaying()) {
                this.playback.setCursor(this.playback.getStartTime());
                this.timeline.setCustomTime(this.vStartDateTime.getTime());
                this.isPlaying = true;
                this.isResume = false;
                this.hideFloorMapSelector();
                this.disableDateSelect2();
                // Display asset tooltip
                this.displayAssetsTooltipsAccordingRowValues();
                // Start playback
                this.playback.start();
            }
        },

        // Init range component for speed for more informations : https://refreshless.com/nouislider/
        initSlider() {
            // Get the html element in template with id slider
            let slider = document.getElementById('slider');

            // Create new slider component on binding of slider element, set the default value to vSpeed value
            noUiSlider.create(slider, {
                start: [this.vSpeed],
                connect: [true, false],
                orientation: "horizontal",
                range: {
                    'min': 1,
                    'max': 5
                },
                step: 1,
                format: wNumb({
                    decimals: 0
                })
            });

            // Add action when user change the value of slider, set the new value in vSpeed vlaue
            slider.noUiSlider.on('update', (values, handle) => {
                this.vSpeed = values[handle];
                document.getElementById("sliderValue").innerHTML = values[handle] + "x";
            });
        },

        // Init start date picker
        initStartDateTimePicker() {
            let self = this;

            let selector = $("#playbackStartDateTimePicker");
            commonVueHelper.initDateTimePicker(
                selector,
                "top-right",
                self.vStartDateTime,
                null,
                new Date()
            );
            selector.on("changeDate", event => {
                // Close timeline
                this.isHistoryDataReady = false;
                if (this.playback) {
                    this.playback.destroy();
                    this.playback = null;
                }
                $("#map").css("height", "calc(100vh - 130px)");
                this.setCurrentOpenedAssetIdInDashboard("");
                if (event.date) {
                    self.vStartDateTime = new Date(event.date.valueOf());
                    self.vStartDateTime.setSeconds(0);
                    self.vStartDateTime.setMilliseconds(0);
                    if (self.vEndDateTime && self.vStartDateTime < self.vEndDateTime) {
                        self.isCorrectDateSelected = true;
                        self.isCorrectEndDateSelected = true;
                    } else {
                        self.isCorrectDateSelected = false;
                        self.isCorrectEndDateSelected = false;
                    }
                } else {
                    self.vStartDateTime = null;
                    self.isCorrectDateSelected = false;
                    self.isCorrectEndDateSelected = false;
                }
                // Reset all leaflet state to false
                self.isResume = false;
                self.isPlaying = false;
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    self.vStartDateTime = null;
                    self.isCorrectDateSelected = false;
                }
            });
        },

        // init end date picker
        initEndDateTimePicker() {
            let self = this;

            let selector = $("#playbackEndDateTimePicker");
            commonVueHelper.initDateTimePicker(
                selector,
                "top-right",
                self.vEndDateTime,
                self.vStartDateTime,
                new Date()
            );
            selector.on("changeDate", event => {
                // Close timeline
                this.isHistoryDataReady = false;
                if (this.playback) {
                    this.playback.destroy();
                    this.playback = null;
                }
                $("#map").css("height", "calc(100vh - 130px)");
                this.setCurrentOpenedAssetIdInDashboard("");
                if (event.date) {
                    self.vEndDateTime = new Date(event.date.valueOf());
                    self.vEndDateTime.setSeconds(0);
                    self.vEndDateTime.setMilliseconds(0);
                    if (self.vStartDateTime && self.vEndDateTime > self.vStartDateTime) {
                        self.isCorrectDateSelected = true;
                        self.isCorrectEndDateSelected = true;
                    } else {
                        self.isCorrectDateSelected = false;
                        self.isCorrectEndDateSelected = false;
                    }
                } else {
                    self.vEndDateTime = null;
                    self.isCorrectDateSelected = false;
                    self.isCorrectEndDateSelected = false;
                }
                // Reset all leaflet state to false
                self.isResume = false;
                self.isPlaying = false;
            });
            selector.on("focusout", event => {
                if (!selector.val()) {
                    self.vEndDateTime = null;
                    self.isCorrectDateSelected = false;
                    self.isCorrectEndDateSelected = false;
                }
            });
        },

        // Init vis component to show the timeline for more information :https://visjs.github.io/vis-timeline/docs/timeline/
        initVis(startDateTime=null, endDateTime=null) {

            let timelineData;

            // If start date time and end date time have given create new timeline data set on this periods            
            if (startDateTime && endDateTime) {
                // Get start/end times
                const initStartDate = startDateTime;
                const initEndDate = endDateTime;
                // Create a DataSet with data
                if (this.assetLocationHistory && this.assetLocationHistory.length > 0) {
                    timelineData = new vis.DataSet([{ start: initStartDate, end: initEndDate, content: i18n.t("playback_vizMessage")}]);
                } else {
                    timelineData = new vis.DataSet([{ start: initStartDate, end: initEndDate, content: i18n.t("playback_vizMessageEmpty")}]);
                }
            }

            // Set timeline options
            let timelineOptions = {
                width: "100%",
                height: "120px",
                style: "box",
                axisOnTop: true,
                align: "center",
                showCustomTime: true,
                autoResize: true,
                showCurrentTime: false,
                locales: {
                    // create a new locale
                    "fr": {
                        current: "heure",
                        time: "actuelle"
                    },
                    "en": {
                        current: "hour",
                        time: "now"
                    }
                },
                locale: this.user.preferredLanguage || "en" // Set the locale to user preferred language, take en like the default language
            };

            // Reset timeline contains if already exists
            document.getElementById("timeline").innerHTML = "";

            // If timeDate, timeline initialisation is called with start and end date time, else init with current date and time
            if (timelineData && typeof timelineData !== 'undefined') {
                this.timeline = new vis.Timeline(document.getElementById('timeline'), timelineData, timelineOptions);
                // If playback is init the initVis function is called when user change his language init the cursor with playback time
                const initDateTime = this.playback && this.isHistoryDataReady ? new Date(this.playback.getTime()) : startDateTime;
                this.timeline.setCustomTime(initDateTime);
            } else {
                this.timeline = new vis.Timeline(document.getElementById('timeline'), null, timelineOptions);
            }

            /**
             * Warning event 'timechange' != 'timechanged'
             * When user drop on timeline, the event 'timechange' is trigger and take each time where cursor passed
             * When user stop dropping of timeline, the event 'timechanged' is trigger and take the time where cursor is now
             */
            this.timeline.on('timechange', this.onCustomTimeChange);
            this.timeline.on('timechanged', this.onCustomTimeChanged);
        },

        // Initialisation of playback module (call only when asset history data have been load) for more information : https://github.com/lufias/LeafletPlayback#readme
        initPlayback() {

            // Initialize playback if not exists (doesn't bindind on letiable playback)
            if (!this.playback) {
                // Customize the playback default options by our option like asset icons ....
                let playbackOptions = {
                    // layer and marker options
                    layer: {
                        pointToLayer : function(featureData, latlng){
                            let result = {};

                            if (featureData && featureData.properties && featureData.properties.path_options){
                                result = featureData.properties.path_options;
                            }

                            if (!result.radius){
                                result.radius = 5;
                            }

                            return new L.CircleMarker(latlng, result);
                        }
                    },

                    marker: (item) => {
                        if (item.properties.asset) {
                            let iconObj = {
                                shadowUrl: null
                            };
                            const customIcon = this.getAssetIcon(item.properties.asset);
                            return {
                                icon: customIcon.icon
                            };
                        } else {
                            return {
                                getPopup: () =>  ''
                            };
                        }
                    },
                    tickLen: 1000,
                    dateControl: true,
                    staleTime: 1000,
                    fadeMarkersWhenStale: true // Hide marker when data is not present at time
                };

                // Create new playback and binding on our leaflet map
                this.playback = new Playback(window.leafletMap, null, this.onPlaybackTimeChange, playbackOptions);
            }

            if (this.assetLocationHistory  && this.assetLocationHistory.length > 0) {
                let playbackData = [];
                // If is multiple days periods and current periods step is different to zero, update previous data with asset location history
                if (this.isMultipledDaysSelected && this.currentPeriodStep !== 0) {
                    // playbackData will be previous data + asset location
                    playbackData = this.previousHistoryData || [];
                    // Get previous data target asset
                    const previousTargetAssets = playbackData.map((data) => {
                        return data.properties.asset;
                    });
                    for (let data of this.assetLocationHistory) {
                        // Verify if asset which associated to dat is already on previous or not
                        const targetAsset = data.properties.asset;
                        if (targetAsset && targetAsset.id) {
                            const isPreviousAsset = _.find(previousTargetAssets, {id: targetAsset.id});
                            if (isPreviousAsset) {
                                playbackData = playbackData.map((previousData) => {
                                    if (previousData.properties.asset && previousData.properties.asset.id === targetAsset.id) {
                                        // Append existing coordinates and time
                                        previousData.geometry.coordinates.push(...data.geometry.coordinates);
                                        previousData.properties.time.push(...data.properties.time);
                                    }
                                    return previousData;
                                })
                            } else {
                                // Is new asset add in playback data
                                playbackData.push(data);
                            }
                        } else {
                            // Is fake asset add in playback data
                            playbackData.push(data);
                        }
                    }
                } else {
                    // If is not multiple days or is first request result of multiple days set the data of assetLocationHistory
                    playbackData = this.assetLocationHistory;
                }
                // Keep previous playback data
                this.previousHistoryData = playbackData;
                // Keep the data according to filter
                const dataToDisplayed = this.getPlaybackDataAccordingFilter();
                // Set the data which must be load
                this.playback.setData(dataToDisplayed);
                // Set the speed by the contains of vSpeed letiable
                this.playback.setSpeed(this.vSpeed);
                // Set the boolean to true to enabled play button
                this.isHistoryDataReady = true;
                // Set the current floor history data
                this.currentFloorHistoryData = this.getSelectedFloorId;
                // Hide map floor selection
                this.hideFloorMapSelector();
                // Start playback
                this.playback.start();
                // Diplay asset tooltip
                this.displayAssetsTooltipsAccordingRowValues();
                // Init marker action
                this.addOnClickOnMarker();
                // Set the boolean to disable the button play and enable the buttons resume, stop....
                this.isPlaying = true;
                // Set playback period if has value, this can start play where user stop cursor
                if (this.timeBeforeSendRequest) {
                    this.playback.setCursor(this.timeBeforeSendRequest);
                }
            } else {
                // Enable the start and end date
                this.enableDateSelect2();
            }
        },

        // Method calls to disable the select start date and end date
        disableDateSelect2() {
            $("#playbackStartDateTimePicker").attr("disabled", "true");
            $("#playbackEndDateTimePicker").attr("disabled", "true");
        },

        // Method calls to enable the select start date and end date
        enableDateSelect2() {
            $("#playbackStartDateTimePicker").removeAttr("disabled");
            $("#playbackEndDateTimePicker").removeAttr("disabled");
        },

        // This function is used to hide floor map selector
        hideFloorMapSelector() {
            $("#floorSelectorDropdown").hide();
        },

        // This function is used to show floor map selector
        showFloorMapSelector() {
            $("#floorSelectorDropdown").show();
        },

        // Method used to return the icon of asset by its body
        getAssetIcon(asset) {
            let iconObj = {
                shadowUrl: null
            };

            iconObj.iconAnchor = new L.Point(26, 60);
            iconObj.iconSize = new L.Point(51, 60);

            let iconUrl = "";
            if (asset.isDefaultCategory) {
                // If the asset category is a default category, use png image saved in public folder,
                // for performance reasons.
                iconUrl = "/assets/categories/" + asset.categoryName + "/Icon_marker.png";
                iconObj.iconUrl = iconUrl;
                let generatedClass = "asset_" + asset.tagMacAddress;
                iconObj.className = "leaflet-autocalibrationtag-location-mouse-marker " + generatedClass;
                return {
                    icon: L.icon(iconObj)
                };
            } else {
                // If the category is a custom category, we will use the customicon base64 image
                if (asset.categoryImage && asset.categoryImage.trim() !== "") {
                    iconUrl = asset.categoryImage;
                } else {
                    iconUrl = "/assets/categories/ASSET_C_CUSTOM/Icon_marker.png";
                }
                iconObj.iconUrl = iconUrl;
                let backgroundStyle = "emptyDefaultMarkerModelLarge";
                let iconStyle = "trackingDashboardCustomIconCatLarge";

                iconObj.className = "leaflet-autocalibrationtag-location-mouse-marker " + backgroundStyle;
                iconObj.html = '<img id="asset_' + asset.tagMacAddress + '" class="' + iconStyle + '" src="' + iconUrl + '" />';

                return {
                    icon: L.divIcon(iconObj)
                };
            }
        },

        // Method used to display the information on asset in map
        displayAssetsTooltipsAccordingRowValues() {
            if (this.playback && window.leafletMap) {
                // Init asset count to 0
                this.currentPlaybackAsset = 0;
                // For each assets, update the tooltip
                for (let markerIdx in window.leafletMap._layers) {
                    if (window.leafletMap._layers.hasOwnProperty(markerIdx)
                        && window.leafletMap._layers[markerIdx].feature
                        && window.leafletMap._layers[markerIdx].feature.properties
                        && window.leafletMap._layers[markerIdx].feature.properties.asset
                    ) {
                        let marker = window.leafletMap._layers[markerIdx];
                        if (marker.options.opacity !== 0) {
                            // Increment the number of playaback asset
                            this.currentPlaybackAsset += 1;
                        }
                        let asset = window.leafletMap._layers[markerIdx].feature.properties.asset;
                        let tooltipContent = this.getTooltipContentAccordingRowValues(asset);
                        if (marker && marker.getTooltip()) {
                            marker.setTooltipContent(tooltipContent);
                        } else {
                            let tooltipObj = {
                                className: "assetLocationHistoryTooltip",
                                permanent: true,
                                direction: "right"
                            };
                            tooltipObj.offset = new L.Point(15, -35);
                            marker.bindTooltip(tooltipContent, tooltipObj);
                        }

                        if (marker.options.opacity !== 0 && this.isAssetsLabelsDisplayed) {
                            marker.openTooltip();
                        } else {
                            marker.closeTooltip();
                        }
                    }
                }
            }
        },

        // Method called to get tooltip HTML content according current asset to display and assets labels rows values
        getTooltipContentAccordingRowValues(asset) {
            let tooltipHTML = '<div class="assetLocationHistoryTooltipContainer" data-assetid="' + asset.id + '">';
            if (this.assetsLabelsDisplayedValues.row1 != "MAP_NONE") {
                tooltipHTML += "<span>" + this.getRowValueForAssetByLabelName(asset, this.assetsLabelsDisplayedValues.row1) + "</span>";
            }
            if (this.assetsLabelsDisplayedValues.row2 != "MAP_NONE") {
                tooltipHTML += "<span>" + this.getRowValueForAssetByLabelName(asset, this.assetsLabelsDisplayedValues.row2) + "</span>";
            }
            if (this.assetsLabelsDisplayedValues.row3 != "MAP_NONE") {
                tooltipHTML += "<span>" + this.getRowValueForAssetByLabelName(asset, this.assetsLabelsDisplayedValues.row3) + "</span>";
            }
            if (this.assetsLabelsDisplayedValues.row4 != "MAP_NONE") {
                tooltipHTML += "<span>" + this.getRowValueForAssetByLabelName(asset, this.assetsLabelsDisplayedValues.row4) + "</span>";
            }
            tooltipHTML += "</div>";
            return tooltipHTML;
        },

        // Method called to get the right value of asset key according labelName
        getRowValueForAssetByLabelName(asset, labelName) {
            let value = "N/A";
            switch (labelName) {
                case "MAP_ASSET_NAME":
                    value = asset.assetName;
                    break;
                case "MAP_CATEGORY":
                    if (asset.categoryName) {
                        if (asset.isDefaultCategory) {
                            value = i18n.t(asset.categoryName);
                        } else {
                            value = asset.categoryName;
                        }
                    } else {
                        value = "N/A";
                    }
                    break;
                case "MAP_SUB_CATEGORY":
                    if (asset.subcategoryName) {
                        if (asset.isDefaultSubcategory) {
                            value = i18n.t(asset.subcategoryName);
                        } else {
                            value = asset.subcategoryName;
                        }
                    } else {
                        value = "N/A";
                    }
                    break;
                case "MAP_UNIT":
                    value = i18n.t(asset.assetUnit);
                    break;
                case "MAP_TAG_SERIAL_NUMBER":
                    value = asset.tagSerialNumber ? asset.tagSerialNumber : "N/A";
                    break;
                case "MAP_TAG_TYPE":
                    if (asset.tagTypeName) {
                        value = i18n.t(asset.tagTypeName);
                    } else {
                        value = "N/A";
                    }
                    break;
                case "MAP_TAG_BATTERY_STATUS":
                    if (asset.tagBatteryInstallationDate && asset.tagTypeBatteryLifeTime) {
                        let batteryLifetime = asset.tagTypeBatteryLifeTime;
                        let estimatedBatteryLifetimeEndDate = moment(asset.tagBatteryInstallationDate).add(batteryLifetime, "months");
                        let estimatedBatteryLifetimeEndDateMinus6Months = moment(estimatedBatteryLifetimeEndDate).subtract(6, "months");
                        let estimateBatteryMiddleLifetimeDate = moment(estimatedBatteryLifetimeEndDate).subtract(batteryLifetime / 2, "months");
                        let now = moment();
                        if (now.isAfter(estimatedBatteryLifetimeEndDate) || now.isAfter(estimatedBatteryLifetimeEndDateMinus6Months)) {
                            value = '<img style="width: 17px" alt="Low Battery" src="/assets/battery/battery-charging.svg" /> <span class="lowBattery">Low</span>';
                        } else if (now.isAfter(estimateBatteryMiddleLifetimeDate) && now.isBefore(estimatedBatteryLifetimeEndDateMinus6Months)) {
                            value = '<img style="width: 17px" alt="Medium Battery" src="/assets/battery/battery-charging.svg" /> <span class="mediumBattery">Medium</span>';
                        } else {
                            value = '<img style="width: 17px" alt="High Battery" src="/assets/battery/battery-charging.svg" /> <span class="highBattery">High</span>';
                        }
                    } else {
                        value = "N/A";
                    }
                    break;
                default:
                    value = "N/A";
                    break;
            }
            return value;
        },

        // Execute new request
        executeAssetLocationRequest() {
            // Stop vue tour if enabled
            this.$tours['playbackTour'].stop();
            // It is new request reinitialisation letiable
            this.historyDataPeriodRange = [];
            this.timeline = null;
            this.currentPeriodStep = 0;
            this.timeBeforeSendRequest = null;
            if (this.playback) {
                this.playback.stop();
            }
            // Convert in timestamp the select date and time
            const startTimeStamp = new Date(this.vStartDateTime).getTime();
            const endTimeStamp = new Date(this.vEndDateTime).getTime();
            // Set the current history data floor
            this.currentHistoryDataFloorId = this.getSelectedFloorId;
            // Verify if seleted date is more than 1 day
            const momentStart = moment(this.vStartDateTime);
            const momentEnd = moment(this.vEndDateTime);
            this.isMultipledDaysSelected = momentEnd.diff(momentStart, 'days') > 1 ? true : false;
            // If is multiple data create period interval per day
            if (this.isMultipledDaysSelected) {
                const nbDays = momentEnd.diff(momentStart, 'days');
                const oneDayTimeStamp = 36000 * 24 * 1000; // in milliseconds
                let currentTimeStamp = new Date(this.vStartDateTime).getTime();
                // Loop to create the history data period
                for (let n = 1; n <= nbDays; n++) {
                    // If the loop index is the last set to end the stop date and time which user select
                    if (n === nbDays) {
                        // Append in history data period
                        this.historyDataPeriodRange.push({
                            start: currentTimeStamp,
                            end: new Date(this.vEndDateTime).getTime(),
                            isExecuted: false
                        });
                    } else {
                        // Calculate the next day
                        const stopTimeStamp = moment(currentTimeStamp).add(1, 'days');
                        // Append in history data period
                        this.historyDataPeriodRange.push({
                            start: currentTimeStamp,
                            end: new Date(stopTimeStamp).getTime(),
                            isExecuted: false
                        });
                        currentTimeStamp = new Date(stopTimeStamp).getTime();
                    }
                }
                // Send request with first period not for user choice
                const payload = {
                    startDate: this.historyDataPeriodRange[0].start,
                    endDate: this.historyDataPeriodRange[0].end,
                    siteId: this.siteId,
                    floorId: this.getSelectedFloorId,
                    buildingId: this.getSelectedBuildingId
                };
                // Send request to backend to find the history of assets
                this.getAssetLocationHistory(payload);
            } else {
                this.currentPeriodStep = 0;
                // Append in history data period
                this.historyDataPeriodRange.push({
                    start: startTimeStamp,
                    end: endTimeStamp,
                    isExecuted: false
                });
                // Prepare payload for request
                const payload = {
                    startDate: startTimeStamp,
                    endDate: endTimeStamp,
                    siteId: this.siteId,
                    floorId: this.getSelectedFloorId,
                    buildingId: this.getSelectedBuildingId
                };
                // Send request to backend to find the history of assets
                this.getAssetLocationHistory(payload);
            }
        },

        // Execute request of specific periods
        getAssetLocationInPeriod(startTimeStamp, endTimeStamp) {
            // Prepare payload for request
            const payload = {
                startDate: startTimeStamp,
                endDate: endTimeStamp,
                siteId: this.siteId,
                floorId: this.getSelectedFloorId,
                buildingId: this.getSelectedBuildingId
            };
            this.getAssetLocationHistory(payload);
        },

        // Get the period range of timestamp
        getTimeStampPeriod(timeStamp) {
            let timeStampPeriod = null;
            for (let period of this.historyDataPeriodRange) {
                if (timeStamp >= period.start && timeStamp <= period.end) {
                    timeStampPeriod = period;
                    break;
                }
            }

            return timeStampPeriod;
        },

        // Get the period range index of timestamp
        getTimeStampPeriodIndex(timeStamp) {
            let timeStampPeriodIndex = 0;
            for (let n = 0 ; n < this.historyDataPeriodRange.length; n++) {
                // Period is [start, end[
                if (timeStamp >= this.historyDataPeriodRange[n].start && timeStamp < this.historyDataPeriodRange[n].end) {
                    timeStampPeriodIndex = n;
                    break;
                }
            }

            return timeStampPeriodIndex;
        },

        // Add action on marker click to open asset details
        addOnClickOnMarker() {
            if (this.playback && window.leafletMap) {
                // For each assets, add on click action
                for (let markerIdx in window.leafletMap._layers) {
                    if (window.leafletMap._layers.hasOwnProperty(markerIdx)
                        && window.leafletMap._layers[markerIdx].feature
                        && window.leafletMap._layers[markerIdx].feature.properties
                        && window.leafletMap._layers[markerIdx].feature.properties.asset
                    ) {
                        let marker = window.leafletMap._layers[markerIdx];
                        marker.on("click", (e) => {
                            if (e.target.options.opacity !== 0) {
                                // Get asset id
                                const assetId = e.target.feature.properties.asset.id;
                                // Set currentOpenedAsset is store
                                this.setCurrentOpenedAssetIdInDashboard("");
                                this.setCurrentOpenedAssetIdInDashboard(assetId);
                            }
                        });
                    }
                }
                // The following code allows to register click event on tooltips.
                $(".assetLocationHistoryTooltip")
                    .off()
                    .on("click", e => {
                        console.log($(e.currentTarget).children(".assetLocationHistoryTooltipContainer").data());
                        console.log($(e.currentTarget));
                        if (e.currentTarget) {
                            if ($(e.currentTarget).children(".assetLocationHistoryTooltipContainer").data() && $(e.currentTarget).children(".assetLocationHistoryTooltipContainer").data().assetid) {
                                let assetId = $(e.currentTarget).children(".assetLocationHistoryTooltipContainer").data().assetid;
                                // Set currentOpenedAsset is store
                                this.setCurrentOpenedAssetIdInDashboard("");
                                this.setCurrentOpenedAssetIdInDashboard(assetId);
                            }
                        }
                    });
            }
        },

        // Reset playback to initial state
        resetPlayback() {
            if(this.playback) {
                this.playback.destroy();
                this.playback = null;
            }
            if (this.timeline) {
                this.timeline.destroy();
            }
            this.isHistoryDataReady = false;
            $("#map").css("height", "calc(100vh - 130px)");
            // Close asset detail bar if is oppened
            this.setCurrentOpenedAssetIdInDashboard("");
            $("#playbackStartDateTimePicker").val(null);
            $("#playbackEndDateTimePicker").val(null);
            this.vStartDateTime = null;
            this.vEndDateTime = null;
            this.isCorrectDateSelected = false;
            this.isCorrectEndDateSelected = false;
            this.timeBeforeSendRequest = null;
            this.isPlaying = false;
            this.isResume = false;
            this.currentFloorHistoryData = null;
            this.currentHistoryDataFloorId = null;
            this.isMultipledDaysSelected = false;
            this.previousHistoryData = null;
            this.historyDataPeriodRange = [];
            this.currentPeriodStep = 0;
            this.timeBeforeSendRequest = null;
            this.currentPlaybackAsset = 0;
        },

        // Function used to return a JSON object containing list of active Filters
        getFormatedFilters() {
            let filters = {
                assets: [],
                categories: [],
                units: [],
                tagTypes: [],
                tagSerialNumbers: []
            };

            // If filtering is disabled, no filters applied.
            if (this.isMapFilteringEnabled == false) {
                return filters;
            }

            for (let i = 0; i < this.activeFilters.length; i++) {
                switch (this.activeFilters[i].keyLabel) {
                    case "MAP_ASSET_NAME":
                        filters.assets.push(this.activeFilters[i].value);
                        break;
                    case "MAP_TAG_SERIAL_NUMBER":
                        filters.tagSerialNumbers.push(
                            this.activeFilters[i].value
                        );
                        break;
                    case "MAP_CATEGORY":
                        filters.categories.push(this.activeFilters[i].keyValue);
                        break;
                    case "MAP_UNIT":
                        filters.units.push(this.activeFilters[i].keyValue);
                        break;
                    case "MAP_TAG_TYPE":
                        filters.tagTypes.push(this.activeFilters[i].keyValue);
                        break;
                }
            }
            return filters;
        },

        // Return the playback data according to filter => Call inside insidePlayback and updatePlaybackDataAccordinFilter methods
        getPlaybackDataAccordingFilter() {
            let returnData = this.previousHistoryData || [];
            // Verify if filter is enable else return the previousData
            if (this.isMapFilteringEnabled) {
                // Loop on asset location history data to keep only asset according to filter
                let filters = this.getFormatedFilters();
                if (filters) {
                    // Sort according asset name
                    if (filters.assets && filters.assets.length > 0) {
                        // Put the asset name to upper case
                        filters.assets = filters.assets.map((assetName) => assetName.toUpperCase());
                        for (let i = 0; i < returnData.length; i++) {
                            returnData = _.reject(returnData, function(data) {
                                // Reject all asset which name is not in filter
                                const asset = data.properties.asset;
                                return asset && !_.includes(filters.assets, asset.assetName.toUpperCase());
                            });
                        }
                    }

                    // Sort according asset categories
                    if (filters.categories && filters.categories.length > 0) {
                        for (let i = 0; i < returnData.length; i++) {
                            returnData = _.reject(returnData, function(data) {
                                // Reject all asset which name is not in filter
                                const asset = data.properties.asset;
                                return asset && !_.includes(filters.categories, asset.categoryName);
                            });
                        }
                    }

                    // Sort according asset tag serial number
                    if (filters.tagSerialNumbers && filters.tagSerialNumbers.length > 0) {
                        // Put all mac addresses in uppercase for later string compare
                        filters.tagSerialNumbers = filters.tagSerialNumbers.map(function (serial) {
                            return serial.toUpperCase()
                        });
                        for (let i = 0; i < returnData.length; i++) {
                            returnData = _.reject(returnData, function(data) {
                                // Reject all asset which name is not in filter
                                const asset = data.properties.asset;
                                return asset && !_.includes(filters.tagSerialNumbers, asset.tagSerialNumber.toUpperCase());
                            });
                        }
                    }

                    // Sort according asset tag type
                    if (filters.tagTypes && filters.tagTypes.length > 0) {
                        for (let i = 0; i < returnData.length; i++) {
                            returnData = _.reject(returnData, function(data) {
                                // Reject all asset which name is not in filter
                                const asset = data.properties.asset;
                                return asset && !_.includes(filters.tagTypes, asset.tagTypeName);
                            });
                        }
                    }

                    // Sort according asset unit
                    if (filters.units && filters.units.length > 0) {
                        for (let i = 0; i < returnData.length; i++) {
                            returnData = _.reject(returnData, function(data) {
                                // Reject all asset which name is not in filter
                                const asset = data.properties.asset;
                                return asset && !_.includes(filters.units, asset.assetUnit);
                            });
                        }
                    }
                }
            }

            return returnData;
        },

        // Set the playback data according to filter choice => Call when user executed action on filter element enable filter apply some value ...
        updatePlaybackDataAccordingFilter() {
            if (this.playback && this.isHistoryDataReady) {
                // Get playback data according to filter
                const filterData = this.getPlaybackDataAccordingFilter();
                // Get playback current time
                const time = this.playback.getTime();
                // Set playback data
                this.playback.clearData();
                this.playback.setData(filterData);
                this.playback.setCursor(time);
                // this.displayAssetsTooltipsAccordingRowValues();
                // Init marker action
                this.addOnClickOnMarker();
            }

        }

    },
    components: {
        // -- Components -- List of local components used in the current template
        // --
        "app-locationmap": LocationMap,
        "app-accesserrorportlet": AccessErrorPortlet
    },

    beforeRouteLeave(to, from, next) {
        this.resetMapMode();
        this.resetSearchedItems();
        next();
    }
};
</script>

<style scoped>
.baseTemplate {
    display: contents;
}
.sliderValue {
    display: block;
    padding-top: 1px;
}
.actionControlContainer {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: -3px;
}
.sliderContainer {
    margin-top: 12px;
    margin-bottom: 4px;
}
.timelineContainer {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
}
.selectDateInput {
    height: 35px;
    width: 180px;
}
.assetCount {
    position: absolute;
    right: 15px;
    margin-top: 10px;
    background: #5d78ff;
    color: white;
    padding: 1px 11px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 6px;
}
</style>
<style>
.datetimeControl {
    font-size: 13px !important;
    font-weight: 500 !important;
}
</style>
